<template>
  <div class="account">
    <!-- register_annual_subheader -->
    <section id="sub_header_box" class="manual manual_pdf">
      <div class="w1300">

        <div class="sub_header_description txt-dark_gray">
          <div class="card_item manual_pdf">
            <h4 class="card_title txt-bold">糖尿病疾病介紹</h4>
            <ul class="card_list">
              <li class="small">台大醫院北護分院李伊真醫師編輯</li>
              <li class="small">台大醫院北護分院楊昆澈醫師初審</li>
              <li class="small">台灣居家醫療醫學會理事長郭啟昭醫師複審</li>
            </ul>
          </div>
        </div>

        <img class="heart_bg" src="@/statics/img/index/icon_heart.svg" alt="">

      </div>
    </section>

    <div id="pdf_container" style="background-color: #fff; position: relative">

      <!-- PDF.js -->
      <div id="pdfJS">
        <div class="w1300">

          <!-- <canvas id="the-canvas"></canvas> -->
          <!-- <pdf src="../../stat"></pdf> -->

          <div class="button_box">
            <button id="prev" class="btn p">Previous</button>
            <button id="next" class="btn p">Next</button>
            &nbsp; &nbsp;
            <span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
          </div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
// import pdf from 'vue-pdf';
// import pdf from 'vue-pdf';
// import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory';

export default {
  name: 'AccountManualPdf',
  data() {
    return {

    };
  },
  components: {
    // pdf,
  },
  computed: {
    // mpdf() {
    //   return pdf.createLoadingTask({ url: './pdf/test.pdf', CMapReaderFactory });
    // },
  },
  // mounted() {
  //   // If absolute URL from the remote server is provided, configure the CORS
  //   // header on that server.
  //   const url = '@/statics/pdf/醫師手冊書冊-糖尿病疾病介紹.pdf';

  //   // Loaded via <script> tag, create shortcut to access PDF.js exports.
  //   const pdfjsLib = window['pdfjs-dist/build/pdf'];

  //   // The workerSrc property shall be specified.
  //   // pdfjsLib.GlobalWorkerOptions.workerSrc = '//mozilla.github.io/pdf.js/build/pdf.worker.js';
  //   pdfjsLib.GlobalWorkerOptions.workerSrc = 'lib/pdf-js/build/pdf.worker.js';

  //   let pdfDoc = null;
  //   let pageNum = 1;
  //   let pageRendering = false;
  //   let pageNumPending = null;
  //   const scale = 3;
  //   const canvas = document.getElementById('the-canvas');
  //   const ctx = canvas.getContext('2d');

  //   /**
  //    * Get page info from document, resize canvas accordingly, and render page.
  //    * @param num Page number.
  //    */
  //   function renderPage(num) {
  //     pageRendering = true;
  //     // Using promise to fetch the page
  //     pdfDoc.getPage(num).then((page) => {
  //       const viewport = page.getViewport({ scale });
  //       canvas.height = viewport.height;
  //       canvas.width = viewport.width;

  //       // Render PDF page into canvas context
  //       const renderContext = {
  //         canvasContext: ctx,
  //         viewport,
  //       };
  //       const renderTask = page.render(renderContext);

  //       // Wait for rendering to finish
  //       renderTask.promise.then(() => {
  //         pageRendering = false;
  //         if (pageNumPending !== null) {
  //           // New page rendering is pending
  //           renderPage(pageNumPending);
  //           pageNumPending = null;
  //         }
  //       });
  //     });

  //     // Update page counters
  //     document.getElementById('page_num').textContent = num;
  //   }

  //   /**
  //    * If another page rendering in progress, waits until the rendering is
  //    * finised. Otherwise, executes rendering immediately.
  //    */
  //   function queueRenderPage(num) {
  //     if (pageRendering) {
  //       pageNumPending = num;
  //     } else {
  //       renderPage(num);
  //     }
  //   }

  //   /**
  //    * Displays previous page.
  //    */
  //   function onPrevPage() {
  //     if (pageNum <= 1) {
  //       return;
  //     }
  //     pageNum -= 1;
  //     queueRenderPage(pageNum);
  //   }
  //   document.getElementById('prev').addEventListener('click', onPrevPage);

  //   /**
  //    * Displays next page.
  //    */
  //   function onNextPage() {
  //     if (pageNum >= pdfDoc.numPages) {
  //       return;
  //     }
  //     pageNum += 1;
  //     queueRenderPage(pageNum);
  //   }
  //   document.getElementById('next').addEventListener('click', onNextPage);

  //   /**
  //    * Asynchronously downloads PDF.
  //    */
  //   pdfjsLib.getDocument(url).promise.then((pdfDoc_) => {
  //     pdfDoc = pdfDoc_;
  //     document.getElementById('page_count').textContent = pdfDoc.numPages;

  //     // Initial/first page rendering
  //     renderPage(pageNum);
  //   });
  // },
};
</script>
